import React from "react";
import { useAppSelector } from "store";
import Snackbar from "./Snackbar";

const SnackbarProvider = () => {
  const { snackbar } = useAppSelector((state) => state.common);

  return (
    <div className="fixed bottom-5 right-5 flex flex-col items-end gap-2.5 z-50">
      {snackbar.map((item, index) => (
        <Snackbar data={item} key={`snackbarItem_${index}`} isLastItem={index === snackbar.length - 1} />
      ))}
    </div>
  );
};

export default SnackbarProvider;

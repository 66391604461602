import AWS from "aws-sdk";
import { Buffer } from "buffer";
import { v4 as uuidv4 } from "uuid";
interface IS3Upload {
  files: any[];
  folderName?: string;
  bucketName?: string;
}
interface IS3SingleUpload {
  file: any;
  folderName?: string;
  bucketName?: string;
}

const useS3 = () => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  const s3 = new AWS.S3();

  async function uploadFiles({ files, folderName = "style_temp/", bucketName = process.env.REACT_APP_BUCKET_NAME as string }: IS3Upload) {
    const uploadedFiles = await Promise.all(
      files.map(async (file: any) => {
        const params = {
          Bucket: bucketName,
          Key: folderName + uuidv4(),
          Body: file,
          ACL: "public-read",
          ContentType: file?.type,
        };

        return await s3.upload(params).promise();
      })
    );

    return uploadedFiles;
  }

  async function uploadFile({ files, folderName = "style_temp/", bucketName = process.env.REACT_APP_BUCKET_NAME as string }: IS3Upload) {
    const params = {
      Bucket: bucketName,
      Key: folderName + uuidv4(), //UID gelecek
      Body: files[0],
      ACL: "public-read",
      ContentType: files[0]?.type,
    };
    const res = await s3.upload(params).promise();

    return [res];
  }

  async function uploadBase64({ file, folderName = "style_temp/", bucketName = process.env.REACT_APP_BUCKET_NAME as string }: IS3SingleUpload) {
    const base64Data = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""), "base64");

    const type = file.split(";")[0].split("/")[1];

    const params = {
      Bucket: bucketName,
      Key: folderName + "canvasItem_" + uuidv4(),
      Body: base64Data,
      ACL: "public-read",
      ContentEncoding: "base64", // required
      ContentType: `image/${type}`, // required. Notice the back ticks
    };
    const res = await s3.upload(params).promise();

    return [res];
  }

  async function handleUpload({ files, folderName = "style_temp/", bucketName = process.env.REACT_APP_BUCKET_NAME as string }: IS3Upload) {
    if (files.length === 1) return uploadFile({ files, folderName, bucketName });
    else return uploadFiles({ files, folderName, bucketName });
  }

  return {
    uploadFile: uploadFile,
    uploadFiles: uploadFiles,
    handleUpload: handleUpload,
    uploadBase64: uploadBase64,
  };
};

export default useS3;

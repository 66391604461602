import clsx from "clsx";
import Button from "components/Button";
import Search from "components/Search";
import { useIsMobile } from "hooks/useIsMobile";
import { IconDesign, IconPlus, IconTrain } from "icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { AnalyticsEvent } from "utils";

const CommonHeader = ({ isFixed }: any) => {
  const navigate = useNavigate();
  const { isMainMenuFixed, isAuthorized } = useAppSelector((state) => state.common);
  const isMobile = useIsMobile();

  return (
    <div className="flex w-full justify-between px-5">
      <div className={clsx("flex w-full transition-all duration-500", isFixed || isMainMenuFixed ? "" : "")}>{/* <Search /> */}</div>
      <div className="flex gap-[5px]">
        <Button
          className="btn-primary-small w-[104px] xs:!w-fit !bg-purple hover:!bg-pink"
          disabled={!isAuthorized}
          onClick={() => {
            AnalyticsEvent("start_new_training", "", "click");
            navigate("/new-training");
          }}
        >
          {isMobile ? (
            <IconDesign className="w-[18px] h-[18px]" />
          ) : (
            <>
              <IconPlus className="w-5 h-5" />
              Training
            </>
          )}
        </Button>
        <Button
          className="btn-primary-small w-[104px] xs:!w-fit"
          disabled={!isAuthorized}
          onClick={() => {
            AnalyticsEvent("start_new_design", "", "click");
            navigate("/design");
          }}
        >
          {isMobile ? (
            <IconTrain className="w-[18px] h-[18px]" />
          ) : (
            <>
              <IconPlus className="w-5 h-5" />
              Design
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CommonHeader;

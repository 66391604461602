import { PATHS } from "router/config/paths";
import beepSound from "../sound/beep.mp3";

export const clipboardCopy = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
  } catch {
    window.navigator["clipboard"].writeText(text);
  }
};

export const downloadImages = (imageURLs: string[]) => {
  imageURLs.forEach((imageURL, index) => {
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = `image${index}.png`;
    link.click();
  });
};

export function isObjectEqual(obj1: any, obj2: any): boolean {
  // If the types are different, they are not equal
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // If they are primitive values, compare them using strict equality
  if (typeof obj1 !== "object" || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // Compare the properties of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!isObjectEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export const isObjectEmpty = (object: any) => Object.keys(object).length === 0;

export const formatDate = (inputDate: string, formatType = 0 as any) => {
  if (formatType === 0) {
    const date = new Date(inputDate);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getDate().toString().padStart(2, "0")}.${date.getFullYear()} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;

    return formattedDate;
  } else if (formatType === 1) {
    const date = new Date(inputDate);

    // Checking if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    // Extracting month, day, and year
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };

    const formattedDate = date.toLocaleDateString("en-US", options);

    // Manually add a comma after the day
    const [month, day, year] = formattedDate.split(" ");

    return `${month} ${day} ${year}`;
  }

  return inputDate;
};

export function playSound(sound = beepSound) {
  const audio = new Audio(sound);
  audio.play();
}

import ReactGA from "react-ga4";

export function AnalyticsEvent(event_name: string, category: string, action: string) {
  ReactGA.event(event_name, {
    category: category,
    action: action,
  });
}

export function getClearPath(path: PATHS) {
  // Split the path at the colon and take the first part
  let clearPath = path.split(":")[0];
  // Remove any trailing slash if present
  if (clearPath.endsWith("/")) {
    clearPath = clearPath.slice(0, -1);
  }

  return clearPath;
}

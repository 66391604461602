import React, { useState } from "react";
import clsx from "clsx";

const Checkbox = (props: any, ref: any) => {
  const { className, children, onChange, containerClassName, ...etc } = props;
  const [isChecked, setIsChecked] = useState(etc.checked);

  React.useEffect(() => {
    setIsChecked(etc.checked);
  }, [etc.checked]);

  return (
    <label
      className={clsx("checkbox", containerClassName)}
      onClick={(e) => {
        setIsChecked((prev: any) => !prev);
        if (etc.disabled) return;
        if (onChange) onChange(e, isChecked);
        e.preventDefault();
      }}
    >
      <input type="checkbox" className="hidden" ref={ref} onChange={onChange} {...etc} />
      <span className={clsx(className, "flex-center")}>
        <div className="flex-center h-2.5 w-2.5 rounded-sm border border-black">{isChecked ? <div className="w-1.5 h-1.5 rounded-[1px] bg-black "></div> : <></>}</div>
      </span>
      {children && <div className="flex">{children}</div>}
    </label>
  );
};

export default React.forwardRef(Checkbox);

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Button from "components/Button";
import Modal from "components/Modal";
import Table, { ITableHeader } from "components/Table";
import { IconBilling, IconClose, IconCollection, IconDesign, IconDownload, IconShare, IconShared, IconTrain } from "icons";
import ChoosePlan from "./ChoosePlan";
import teamsService from "api/teams/teams.service";
import { useNavigate } from "react-router-dom";
import paymentService from "api/payment/payment.service";
import userService from "api/user/user.service";
import { useAppSelector } from "store";
import { formatDate } from "utils";

enum PaymentStatus {
  Failed = "Failed",
  Waiting = "Waiting",
  Paid = "Paid",
}

function getStatus(status: any) {
  switch (status) {
    case "paid":
      return PaymentStatus.Paid;
    case "waiting":
      return PaymentStatus.Waiting;
    case "failed":
      return PaymentStatus.Failed;

    default:
      return "";
  }
}

function getColor(status: any) {
  switch (status) {
    case "failed":
      return "text-orange";
    case "waiting":
      return "text-grey";
    case "paid":
      return "text-green";
    default:
      return "text-white";
  }
}

const DownloadComponent = ({ item }: any) => {
  const handleDownload = async () => {
    const downloadPath = item.pdf_path;

    const link = document.createElement("a");
    link.href = downloadPath;
    link.setAttribute("download", "invoice.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  return <IconDownload className="cursor-pointer w-5 h-5 text-white" onClick={handleDownload} />;
};

const InvoiceBreakdown = ({ onClose, show }: any) => (
  <Modal onClose={onClose} show={show} bodyClassName="!rounded-[5px] !border-none overflow-hidden">
    <div className="flex flex-col bg-bg w-[420px]">
      <div className="p-2 bg-bg-3 text-h6 text-white rounded-t-[5px]">Invoice Breakdown</div>
      <div className="flex flex-col gap-3 w-full rounded-md p-3">
        <div className="flex justify-between w-full">
          <span className="text-bodyMd text-white">Monthly Design Studio Subscription</span>
          <span className="text-bodyMd text-white">$995</span>
        </div>
        <div className="flex flex-col gap-[5px]">
          <span className="text-15bodySm text-grey">$450 x 2 seats</span>
          <span className="text-bodySm text-grey">10,000 x 2 Monthly Design Credits</span>
          <span className="text-bodySm text-grey">20 x 2 Monthly Train Credits</span>
        </div>
      </div>
      <div className="flex bg-bg-2 gap-2.5 p-2.5 border border-bg-3 rounded-b-[5px]">
        <Button className="btn-secondary w-full" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  </Modal>
);

const CancelPlan = ({ onClose, show, userId }: any) => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [usedInfo, setUsedInfo] = useState<any>({});

  useEffect(() => {
    if (!show) return;

    userService
      .getUserDetail({
        user_id: userId,
      })
      .then((res) => setUsedInfo(res.responseData.used_info));
  }, [show]);

  function handleCancelation() {
    paymentService.managePortal().then((res) => {
      const url = res.responseData?.portal_url;
      if (url) {
        window.open(url, "_blank");
      }
    });
  }

  return (
    <Modal onClose={onClose} show={show} bodyClassName="!rounded-[5px] !border-none overflow-hidden">
      {isCancelled ? (
        <div className="flex flex-col bg-bg w-[420px]">
          <div className="p-2 bg-bg-3 text-h6 text-white rounded-t-[5px]">Your Plan Cancelled</div>
          <div className="flex flex-col gap-3 w-full rounded-md p-3">
            <span className="text-bodySm text-grey">Your subscription is now cancelled. Your access continues until the end of your billing cycle.</span>
          </div>
          <div className="flex bg-bg-2 gap-2.5 p-2.5 border border-bg-3 rounded-b-[5px]">
            <Button className="btn-secondary w-full" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col bg-bg w-[420px]">
          <div className="p-2 bg-bg-3 text-h6 text-white rounded-t-[5px]">Cancel Plan</div>
          <div className="flex flex-col gap-7 w-full p-3">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2.5">
                <h3 className="text-h3 text-white">If you cancel..</h3>
                <span className="text-bodySm text-grey">Your collections and trainings will be converted to view-only:</span>
              </div>
              <div className="flex w-full gap-2.5">
                <div className="flex flex-col w-full gap-5 p-2.5 border border-bg-3 rounded-[5px]">
                  <IconCollection className="w-8 h-8 text-white" />
                  <div className="flex flex-col gap-[5px]">
                    <span className="text-bodySm text-grey">Collections</span>
                    <h4 className="text-h4 text-white">{usedInfo.collections ?? 0}</h4>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-5 p-2.5 border border-bg-3 rounded-[5px]">
                  <IconTrain className="w-8 h-8 text-orange" />
                  <div className="flex flex-col gap-[5px]">
                    <span className="text-bodySm text-grey">Trainings</span>
                    <h4 className="text-h4 text-white">{usedInfo.trainings ?? 0}</h4>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-5 p-2.5 border border-bg-3 rounded-[5px]">
                  <IconShare className="w-8 h-8 text-white" />
                  <div className="flex flex-col gap-[5px]">
                    <span className="text-bodySm text-grey">Team Members</span>
                    <h4 className="text-h4 text-white">{usedInfo.members ?? 0}</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col gap-4">
              <h5 className="text-h5 text-white">And you’ll miss some cool features:</h5>
              <div className="flex w-full gap-2.5"></div>
            </div> */}
          </div>
          <div className="flex bg-bg-2 gap-2.5 p-2.5 border border-bg-3 rounded-b-[5px]">
            <Button className="btn-secondary w-full" onClick={handleCancelation}>
              Cancel My Plan
            </Button>
            <Button className="btn-primary w-full" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

const PlanBilling = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.common);
  const [showInvoiceBreakdown, setShowInvoiceBreakdown] = useState(false);
  const [showChoosePlan, setShowChoosePlan] = useState(false);
  const [showCancelPlan, setShowCancelPlan] = useState(false);
  const [teamInfo, setTeamInfo] = useState<any>();
  const [hasNoPackage, setHasNoPackage] = useState(true);
  const [packageName, setPackageName] = useState("");
  const [endDate, setEndDate] = useState<any>("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [invoices, setInvoices] = useState<any[]>([]);

  React.useEffect(() => {
    teamsService.getTeamInfo().then((res) => setTeamInfo(res.responseData));
    userService.getUserDetail({ user_id: user.id }).then((res) => {
      setHasNoPackage(res.responseData.package_detail.name === "No Package");
      setPackageName(res.responseData.package_detail.name);
      setEndDate(res.responseData.user_info.end_date);
      setTotalPrice(res.responseData.package_detail.total_price);
    });
    userService.getInvoiceList().then((res) => setInvoices(res.responseData.invoices));
  }, []);

  const headers: ITableHeader[] = [
    {
      key: "date",
      text: "Date",
      render: (item) => <span className="text-h7 text-white">{formatDate(item?.created, 1)}</span>,
    },
    {
      key: "status",
      text: "Status",
      render: (item) => <span className={clsx("text-h7 ", getColor(item?.status))}>{getStatus(item?.status)}</span>,
    },
    {
      key: "amount",
      text: "Amount",
      render: (item) => <span className="text-h7 text-white">${item?.total}</span>,
    },
    {
      key: "plan",
      text: "Plan",
      render: (item) => <span className="text-h7 text-white">{item?.plan}</span>,
    },
    {
      key: "download",
      text: "",
      width: "24px",
      align: "flex-end",
      render: (item) => <DownloadComponent item={item} />,
    },
  ];

  return (
    <div className="flex flex-col gap-5">
      {showChoosePlan && (
        <div className="fixed flex z-50 top-0 left-0 bg-black h-screen w-screen">
          <div className="cursor-pointer flex-center absolute top-10 right-20 h-9 w-9 bg-bg rounded-full z-50" onClick={() => setShowChoosePlan(false)}>
            <IconClose className="text-white w-3 h-3" />
          </div>
          <ChoosePlan />
        </div>
      )}
      <CancelPlan userId={user.id} show={showCancelPlan} onClose={() => setShowCancelPlan(false)} />
      <InvoiceBreakdown show={showInvoiceBreakdown} onClose={() => setShowInvoiceBreakdown(false)} />
      <div className="flex flex-col p-2.5 gap-5 rounded-md bg-bg border border-bg-3">
        <div className="flex flex-col gap-[5px]">
          <span className="text-bodySm text-grey">Current Plan</span>
          <span className="text-h3 text-white">{packageName}</span>
        </div>
        {!hasNoPackage && (
          <span className="text-bodySm text-white">
            Your monthly subscription will automatically renew on {formatDate(endDate, 1)}. You will be charged ${totalPrice} plus applicable tax.
          </span>
        )}
        <div className="flex gap-2.5">
          <Button className="btn-primary-small" onClick={() => navigate("/pricing")}>
            Upgrade Plan
          </Button>
          {!hasNoPackage && (
            <Button className="btn-secondary-small" onClick={() => setShowCancelPlan(true)}>
              Cancel Plan
            </Button>
          )}
        </div>
      </div>
      <div className="flex gap-5 w-full">
        <div className="flex flex-col w-full py-5 px-2.5 gap-5 rounded-md bg-bg border border-bg-3">
          <IconDesign className="w-8 h-8 text-green" />
          <div className="flex flex-col gap-[5px]">
            <span className="text-bodySm text-grey">Monthly Design Credits</span>
            <span className="text-h3 text-white">
              {teamInfo?.design_credits?.monthly_design_credits_used}/{teamInfo?.design_credits?.monthly_design_credits_total}
            </span>
          </div>
        </div>
        <div className="flex flex-col w-full py-5 px-2.5 gap-5 rounded-md bg-bg border border-bg-3">
          <IconTrain className="w-8 h-8 text-orange" />
          <div className="flex flex-col gap-[5px]">
            <span className="text-bodySm text-grey">Monthly Train Credits</span>
            <span className="text-h3 text-white">
              {teamInfo?.train_credits?.monthly_train_credits_used}/{teamInfo?.train_credits?.monthly_train_credits_total}
            </span>
          </div>
        </div>
        <div className="flex flex-col w-full py-5 px-2.5 gap-5 rounded-md bg-bg border border-bg-3">
          <IconShared className="w-8 h-8 text-pink" />
          <div className="flex flex-col gap-[5px]">
            <span className="text-bodySm text-grey">Team members</span>
            <span className="text-h3 text-white">
              {teamInfo?.team_members?.team_members_used}/{teamInfo?.team_members?.team_members_total}
            </span>
          </div>
        </div>

        {
          <div className="flex flex-col w-full py-5 px-2.5 gap-5 rounded-md bg-bg border border-bg-3">
            <IconBilling className="w-8 h-8 text-white" />
            <div className="flex flex-col gap-[5px]">
              <span className="text-bodySm text-grey">Total Invoice</span>
              <span className="text-h3 text-white">{teamInfo?.total_invoice?.current_invoice}</span>
              <Button className="text-green text-bodySm w-fit underline px-0" onClick={() => setShowInvoiceBreakdown((prev) => !prev)}>
                See breakdown
              </Button>
            </div>
          </div>
        }
      </div>
      <div className="flex flex-col gap-5 w-full">
        <h4 className="text-h4 text-white">Invoice History</h4>
        <Table headers={headers} items={invoices} isSelectedRow={(item) => item.isSelected} />
      </div>
    </div>
  );
};

export default PlanBilling;
